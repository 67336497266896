// shared theme local variables
@import 'variables';
@import "@atc/axl-ford/scss/axl-ford";

/* FYC-only components */
@import "components/filters";
@import "components/media_gallery_image";
@import "components/media_gallery_modal";
@import "components/page_overlay";
@import "components/performance-accessibility";
@import "components/sticky_header";

// *** CSS Overrides ***
.ford-landing-content-container {
    font-family: "Ford Antenna", $font-family-base !important;

    // Add underline for link
    >.container a {
        text-decoration: underline !important;
        
        &:hover {
            color: $blue-700 !important;
        }
    
        &:active {
            color: $blue-800 !important;
        }
    }
}

// *** Custom styles for Ford Header/Footer ***
// TODO: Custom variables for header/footer placeholder only (based off of placeholder spec)
// These will be replaced or made official once real specs come in
$gray-200: #C1C1C7;
$gray-300: #acabaf;
$gray-400: #727073;
$gray-600: #59636e;
$gray-700: #424143;


// TODO: Temp for Sticky Header until specs are released
.sticky-container>header {
    background: linear-gradient($white, $blue-100) !important;

    div {
        color: $gray-800;
    }
}

[data-cmp="mediaShowcase"] {
    .badge {
        line-height: 22px;
    }
}

button[data-cmp="desktopWalletLink"] span.text-accent {
    color: $white !important;
}

// Hide container div of Keyword search in mobile mode because the Keyword search textbox doesn't display on FBA
// TODO: this container shouldn't be added to the DOM at all.
@media (max-width: $screen-xs-max) {
    div[data-cmp="stickyContainer"]>div[data-cmp="cntnr-sticky-header"]>div.container>div.row>div:first-child {
        display: none;
    }

    .scroll-detection {
        &.scroll-down-mobile {
            transform: translateY(115px);
        }
    }
}

// Hide top spacing of controls in filter group
@media (min-width: $screen-sm-min) {
    div[data-cmp="filteredResultsControls"]>div {
        div:first-child,
        div:nth-child(2) {
            border-top: 0;
        }
    }

}

.card-image-placeholder {
    min-height: 135px !important;
}